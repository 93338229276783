.container {
  display: inline-block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
  background-color: transparent !important;
  cursor: pointer !important;
}
